<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>查看收益</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card v-loading="loading">
			<p class="title">房产总详情</p>
			<el-row :gutter="24">
				<el-col :span="8">
					<div class="overall income">
						总收益
						<p>{{currencyFormatter(clientTotalIncome)}}</p>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="overall expenses">
						总支出
						<p>{{currencyFormatter(clientTotalExpenses)}}</p>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="overall overdraft">
						当前欠款
						<p>{{currencyFormatter(clientTotalOwn)}}</p>
					</div>
				</el-col>
			</el-row>
			<el-row class="operation-bar">
				<el-col :span="4">
					<p class="title title1">收益情况</p>
				</el-col>
				<el-col :span="20" class="right-part">
					<el-radio-group v-model="radio" size="mini" class="pickrange" @change="handleSelection">
						<el-radio-button label="6">六个月</el-radio-button>
						<el-radio-button label="12">一年</el-radio-button>
						<el-radio-button label="24">二年</el-radio-button>
					</el-radio-group>
					<el-date-picker v-model="daterange" type="monthrange" range-separator="至"
						start-placeholder="开始月份" end-placeholder="结束月份" style="margin-right: 20px"
						@change="dateChange">
					</el-date-picker>
					<el-button type="info" icon="el-icon-search" @click='handleSearch'>搜索</el-button>
				</el-col>
			</el-row>
			<div v-if="isShow" class="chart-wrap">
				<v-chart class="chart" ref="chart" :auto-resize="true" :option="option"
					style="height:280px;width:100%;" />
			</div>
		</el-card>
		<el-card v-loading="loading2">
			<p class="title">房源列表</p>
			<el-table :data="propsList" stripe @sort-change="changeSort">
				<el-table-column type="index">
				</el-table-column>
				<el-table-column prop="address" label="地址" min-width="350" sortable="custom">
				</el-table-column>
				<el-table-column prop="monthlyCheck" label="最新月收益" :formatter="usdFormatter">
				</el-table-column>
				<el-table-column prop="is_leasing" label="出租情况" sortable="custom">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.is_leasing" type="success" size="medium">出租中</el-tag>
						<el-tag v-else type="danger" size="medium">未租</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<router-link :to="`/income/property/${scope.row.id}`" style="text-decoration:none;">
							<el-link type="primary">查看详情</el-link>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
	</div>
</template>

<script>
export default {
	name: 'Income',
	created() {
		this.getIncome(this.radio);
		this.getPropsList();
		this.getOverallDraft();
	},
	mounted() {
		window.addEventListener('resize', this.resizeTheChart);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeTheChart);
	},
	data() {
		return {
			radio: 6,
			daterange: null,
			loading: false,
			loading2: false,
			clientTotalIncome: 0,
			clientTotalExpenses: 0,
			clientTotalOwn: 0,
			isShow: false,
			option: {
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: []
				},
				yAxis: {
					type: "value",
					scale: true,
				},
				grid: {
					left: '37px',
					top: '50px',
					right: '25px',
					bottom: '50px'
				},
				series: []
			},
			queryInfo: {
				email: '',
				order: '',
				prop: ''
			},
			propsList: []
		};
	},
	methods: {
		async getIncome(time) {
			const queryInfo = {};
			let now = this.$dayjs().endOf('month');
			if (Array.isArray(time)) {
				queryInfo.startTime = time[0];
				queryInfo.endTime = this.$dayjs(time[1]).endOf('month').$d;
			} else {
				queryInfo.startTime = now.subtract(time - 1, 'month').startOf('month').$d;
				queryInfo.endTime = now.$d;
			}
			queryInfo.cid = window.sessionStorage.getItem('cid');
			this.loading = true;
			const {data: res} = await this.$http.get('client/income', {params: queryInfo});
			this.clientTotalIncome = res.data.totalIn.toFixed(2);
			this.clientTotalExpenses = res.data.totalEx.toFixed(2);
			const xAxisData = [];
			let startTime = queryInfo.startTime;
			while (startTime < queryInfo.endTime) {
				xAxisData.push(this.$dayjs(startTime).format('MM/YYYY'));
				startTime = this.$dayjs(startTime).add(1, 'month').$d;
			}
			this.option.xAxis.data = [...xAxisData];
			const seriesData = [];
			let propCount = 0;
			for (const prop of res.data.properties) {
				startTime = queryInfo.startTime;
				seriesData.push({name: prop.address, data: [], type: 'line'});
				while (startTime < queryInfo.endTime) {
					let checks = prop.monthly_checks.filter(check => new Date(check.sent_date) >= startTime && new Date(check.sent_date) <= this.$dayjs(startTime).endOf('month').$d);
					let checksAmount = checks.reduce((pre, cur) => pre + cur.check_amount, 0.00);
					if (checks.length === 0) seriesData[propCount].data.push('');
					else seriesData[propCount].data.push(checksAmount);
					startTime = this.$dayjs(startTime).add(1, 'month').$d;
				}
				propCount++;
			}
			this.option.series = [...seriesData];
			this.loading = false;
		},
		resizeTheChart() {
			if (this.$refs && this.$refs.chart) {
				this.$refs.chart.resize();
			}
		},
		handleSelection(lable) {
			if (this.daterange) this.daterange = null;
			this.getIncome(lable);
		},
		dateChange() {
			if (!this.daterange) {
				this.radio = 6;
				this.getIncome(this.radio);
			}
		},
		handleSearch() {
			if (!this.daterange) return;
			this.radio = 0;
			this.getIncome(this.daterange);
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getPropsList();
		},
		async getOverallDraft() {
			const {data: res} = await this.$http.get(`/client/own/${window.sessionStorage.getItem('cid')}`);
			if (res.meta.status !== 200) return this.$message.error('获取欠款失败！');
			this.clientTotalOwn = res.data;
		},
		async getPropsList() {
			this.loading2 = true;
			this.queryInfo.email = window.sessionStorage.getItem('email');
			const {data: res} = await this.$http.get('client/propslist', {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取用户房产列表失败！');
			this.propsList = res.data;
			this.isShow = true;
			this.loading2 = false;
		}
	}
}
</script>

<style lang="less" scoped>
.el-card {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 10px;
	}
	.overall {
		font-size: 14px;
		line-height: 24px;
		padding: 10px 20px;
		p {
			font-size: 24px;
			line-height: 36px;
			font-weight: 600;
		}
	}
	.income {
		border: 1px solid #219653;
	}
	.expenses {
		border: 1px solid #2f80ed;
	}
	.overdraft {
		border: 1px solid #eb5757;
	}
	.operation-bar {
		margin-top: 20px;
		.title1 {
			line-height: 40px;
			margin: 0;
		}
		.right-part {
			text-align: right;
			.pickrange {
				margin-right: 20px;
			}
		}
	}
}
</style>